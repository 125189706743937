<template>
  <div class="form-custom section">
    <cube-form
      :model="model"
      class="form-wrapper "
      @submit="submitHandler"
    >
      <cube-form-group class="form-group">
        <cube-form-item :field="fields[0]" />
        <div class="after-space border-bottom-1px" />
        <cube-form-item :field="fields[1]">
          <div class="field-wrapper">
            <div
              class="cube-select"
              :class="changeColor(model.siteValue)"
              @click="showPicker"
            >
              {{ model.siteValue || "请选择" }}
            </div>
            <address-picker
              ref="addressPicker"
              @input="selectHandler"
            />
            <i class="icon-joyo arrows">&#xe66c;</i>
          </div>
        </cube-form-item>
        <div class="after-space border-bottom-1px" />
        <cube-form-item :field="fields[2]" />
        <cube-form-item :field="fields[3]">
          <div class="field-wrapper">
            <cube-select
              v-model="model.scale"
              class="cube-select"
              :options="fields[3].props.options"
              placeholder="请选择"
            />
            <i class="icon-joyo arrows">&#xe66c;</i>
          </div>
        </cube-form-item>
        <div class="after-space border-bottom-1px" />
      </cube-form-group>
      <div class="bottom-postion">
        <cube-button
          type="submit"
          class="btn"
        >
          保存
        </cube-button>
      </div>
    </cube-form>
  </div>
</template>

<script>
import addressPicker from '_c/address/index.vue';
import { custEdit, custUpdate } from '_api/cust';
import { mapGetters } from 'vuex';

export default {
  components: {
    addressPicker
  },
  data() {
    return {
      model: {
        id: '',
        custCn: '',
        addrProvince: '', // 省代码
        province: '',
        addrCity: '', // 市代码
        city: '',
        addrArea: '', // 区代码
        area: '',
        siteValue: '', // 显示的地址
        addrStreet: '',
        scale: ''
      },
      fields: [
        {
          type: 'input',
          modelKey: 'custCn',
          label: '客户名称',
          props: {
            placeholder: '请输入',
            disabled: true
          },
          rules: {
            required: true
          }
        },
        {
          type: 'select',
          modelKey: 'siteValue',
          label: '客户地址',
          rules: {
            required: true
          },
          messages: {
            required: '请选择'
          }
        },
        {
          type: 'textarea',
          modelKey: 'addrStreet',
          label: '详细地址',
          props: { placeholder: '输入详细地址', maxlength: 100 },
          rules: { required: true }
        },
        {
          type: 'select',
          modelKey: 'scale',
          label: '公司规模',
          props: {
            options: []
          },
          rules: {
            required: true
          }
        }
      ]
    };
  },
  computed: {
    ...mapGetters(['dictList'])
  },
  mounted() {
    this.getDataOptions();
  },
  methods: {
    changeColor(value) {
      if (value !== '') {
        return 'color-333';
      }
      return 'color-ccc';
    },
    showPicker() {
      this.$refs.addressPicker.showPicker();
    },
    selectHandler(selectedVal, selectedIndex, selectedTxt) {
      this.model.addrProvince = selectedVal[0];
      this.model.addrCity = selectedVal[1];
      this.model.addrArea = selectedVal[2];
      this.model.province = selectedTxt[0];
      this.model.city = selectedTxt[1];
      this.model.area = selectedTxt[2];
      this.model.siteValue = `${selectedTxt[0]}  ${selectedTxt[1]}  ${selectedTxt[2]}`;
    },
    getInfo() {
      custEdit(this.$route.params.custId).then(res => {
        if (res.flag) {
          let custInfo = res.data;
          for (let i in this.model) {
            this.model[i] = custInfo[i];
          }
          this.model.addrStreet = custInfo.addrStreet || '';
          this.model.id = this.$route.params.custId;
          this.model.siteValue = `${custInfo.province ? custInfo.province : ''} ${custInfo.city ? custInfo.city : ''} ${custInfo.area ? custInfo.area : ''}`;
        } else {
          this.$showToast(res.errorMsg);
        }
      });
    },
    getDataOptions() {
      this.fields[3].props.options = this.dictList.scale;
      this.getInfo();
    },
    submitHandler(e) {
      e.returnValue = false;
      custUpdate(this.model).then(res => {
        if (res && res.flag) {
          this.$showToast('保存成功');
          this.$router.go(-1);
        } else {
          this.$showToast(res.errorMsg);
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped src="@/assets/css/components/btn-bottom.scss"></style>
<style lang="scss" scoped>
.section {
  height: 100%;
  .form-wrapper {
    height: 100%;
    background-color: $color-F7;
    .form-group {
      background-color: $color-FFF;
      .field-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .cube-select {
          flex: 1;
          text-align: left;
        }
        .color-ccc {
          color: $color-CCC;
        }
        .color-333 {
          color: $color-333;
        }
        .arrows {
          color: $color-CCC;
          font-size: 11px;
        }
        .button {
          flex: 0 0 70px;
          color: $color-theme;
          font-weight: 400;
          font-size: 14px;
          text-align: right;
        }
      }
    }
  }
}
</style>
